import settings from './settings';
import staticblack from './staticblack';
import menudata from './menudata';
import banner from './banner';
import outlets from './outlets';
import alloutlets from './alloutlets';
import zonedetail from './zonedetail';
import cartlistdetail from './cartlistdetail';
import cateringcartlistdetail from './cateringcartlistdetail';
import product from './product';
import productdetail from './productdetail';
import addonproduct from './addonproduct';
import login from './login';
import fblogin from './fblogin';
import customerdetail from './customerdetail';
import forgetpassword from './forgetpassword';
import registration from './registration';
import changepassword from './changepassword';
import updatecustomerprofile from './updatecustomerprofile';
import secondaryaddress from './secondaryaddress';
import activitycount from './activitycount';
import corderdetail from './corderdetail';
import porderdetail from './porderdetail';
import orderdetail from './orderdetail';
import printorder from './printorder';
import orderhistory from './orderhistory';
import promotionlist from './promotionlist';
import promotionreceipt from './promotionreceipt';
import applypromotion from './applypromotion';
import rewardearned from './rewardearned';
import rewardredeem from './rewardredeem';
import holidays from './holidays';
import pagedata from './pages';
import contactdata from './contactdata';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  settings: settings,
  staticblack: staticblack,
  menudata: menudata,
  banner: banner,
  outlets: outlets,
  alloutlets: alloutlets,
  zonedetail: zonedetail,
  cartlistdetail: cartlistdetail,
  cateringcartlistdetail: cateringcartlistdetail,
  product: product,
  productdetail: productdetail,
  addonproduct: addonproduct,
  login: login,
  fblogin: fblogin,
  customerdetail: customerdetail,
  forgetpassword: forgetpassword,
  registration: registration,
  changepassword: changepassword,
  updatecustomerprofile: updatecustomerprofile,
  secondaryaddress: secondaryaddress,
  activitycount: activitycount,
  corderdetail: corderdetail,
  porderdetail: porderdetail,
  orderdetail: orderdetail,
  printorder: printorder,
  orderhistory: orderhistory,
  promotionlist: promotionlist,
  promotionreceipt: promotionreceipt,
  applypromotion: applypromotion,
  rewardearned: rewardearned,
  rewardredeem: rewardredeem,
  holidays: holidays,
  pagedata: pagedata,
  contactdata: contactdata
});

export default rootReducer;
